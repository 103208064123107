<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div>
					<el-button style="margin-left: 10px" type="primary" size="large"
						@click="() => (add = !add)"><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button>
				</div>
			</div>

			<el-table :data="tableData" style="width: 100%" row-key="unionId" height="calc(100vh - 220px)"
				@cell-mouse-enetr="obtain" border :header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts">
				<el-table-column label="会员姓名" prop="name" align="center" :show-overflow-tooltip="true" />
				<el-table-column label="会员编码" prop="userName" align="center" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="所属工会" prop="unionNme" align="center" :formatter="quantum"
					:show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="所属部门" prop="departmentName" align="center" :formatter="quantum"
					:show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="操作" align="center" width="240">
					<template #default="scope">
						<el-button size="default" @click="expurgate(scope.row.userId)" class="red"><el-icon
								class="cancel">
								<Delete />
							</el-icon>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<!-- 删除按钮弹窗 -->
		<el-dialog v-model="remove" title="协会管理员删除" width="30%" center>
			<div class="labor">
				<div class="sure">确定删除协会管理员吗？</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
					<el-button class="dsa" size="large" @click="remove = false">取消</el-button>
				</span>
			</template>
		</el-dialog>

		<!-- 添加按钮弹窗 -->
		<el-dialog v-model="add" title="协会管理员添加" width="30%" center>
			<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize" status-icon
				center>
				<!-- 填写会员编码 -->
				<el-form-item label="会员编码" prop="name">
					<el-input v-model="form.name" placeholder="请填写会员编码" size="large"></el-input>
				</el-form-item>
			</el-form>
			<!-- 确定按钮 -->
			<span slot="footer" class="dialog-footer">
				<el-button class="asd" type="primary" size="large" :loading="loa" @click="onSubmit">确定</el-button>
				<el-button size="large" @click="reset">取消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		Delete,
		EditPen,
		Plus
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		onMounted,
		ref,
		onActivated,
		reactive,
	} from "vue";
	// import {tissue,Deletezz,SZargur,SZglur} from '../../utils/api'
	import {
		associationAdministrator,
		administratorAdd,
		administratorDle,
	} from "../../utils/api";
	import {
		ElMessage
	} from "element-plus";
	import qs from "qs";
	import {
		useStore
	} from "vuex";
	export default {
		name: "associationAdministrator",

		setup() {
			// 是否刷新数据
			const route = useRoute();
			let translate = ref(2);
			onActivated(() => {
				translate.value = route.params.UserId;
				if (translate.value == 1) {
					tiss();
				}
			});

			let add = ref(false);

			// 删除按钮
			const affirm = () => {
				let data = {
					associationId: sessionStorage.getItem('activityIds'),
					userId: ids.value,
				};
				administratorDle(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						ElMessage({
							message: "删除成功",
							type: "success",
							center: true,
						});
						remove.value = false;
						tiss();
					} else {
						ElMessage({
							message: res.data.msg,
							type: "warning",
							center: true,
						});
						remove.value = false;
						tiss();
					}
				});
			};
			let ids = ref("");
			const expurgate = (id) => {
				// console.log(id)
				ids.value = id;
				remove.value = true;
			};
			let remove = ref(false);
			let quantum = (row, column, cellValue) => {
				return cellValue.slice(0, 11);
			};

			// 组织管理列表内容
			let texts = ref("加载中");
			let tableData = ref([]);

			// 管理员列表接口
			const tiss = () => {
				let data = {
					associationId: sessionStorage.getItem('activityIds')
				};
				const unionId = ref([])
				associationAdministrator(qs.stringify(data)).then((res) => {
					tableData.value = res.data.data.associationAdminList;
					// console.log(tableData.value, "1111");

					if (res.data.data.associationAdminList.length == 0) {
						texts.value = "暂无数据";
					}
				});
			};
			onMounted(() => {
				tiss();
			});

			const loa = ref(false);
			const reset = () => {
				add.value = !add.value;
				loa.value = false;
				ruleFormRef.value.resetFields();
			};
			// 表单内容
			const ruleFormRef = ref(null);
			const visible = ref(false);
			const form = reactive({
				name: "",
			});
			// 表单校验
			const onSubmit = () => {
				// 校验成功后要调用添加接口
				ruleFormRef.value.validate(async (valid) => {
					// console.log("-----------", valid);
					if (valid == true) {
						loa.value = true;
						let data = {
							associationId: sessionStorage.getItem('activityIds'),
							username: form.name,
						};
						administratorAdd(qs.stringify(data)).then((res) => {
							// console.log(res,"0000000")
							if (res.data.code == 0) {
								loa.value = false;
								form.name = "";
								add.value = false;
								ElMessage({
									message: "添加成功",
									type: "success",
									center: true,
								});
								tiss();
							} else {
								loa.value = false;
								form.name = "";
								add.value = false;
								loa.value = false;
								ElMessage.error(res.data.msg);

							}
						});
					} else {
						loa.value = false;
					}

					console.log(form);
				});
			};

			// 验证表单提示
			const rules = {
				name: [{
					required: true,
					message: "请输入会员编码",
					trigger: "blur"
				}],
			};

			// 列表数据循环
			// const filterTableData = ref([])
			return {
				affirm,
				expurgate,
				remove,
				texts,
				quantum,
				form,
				// 循环列表数据
				// filterTableData,
				tableData,
				// 表单校验
				visible,
				rules,
				onSubmit,
				ruleFormRef,
				add,
				reset,
				loa,
			};
		},
		components: {
			Delete,
			EditPen,
			Plus,
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	.chart-card {
		height: calc(100vh - 145px);
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #ebeef5 !important;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 36.5%;
	}

	.el-form-item {
		display: flex;
		--font-size: 14px;
		margin-bottom: 45px;
	}

	.el-form-item .el-button {
		margin-left: 75px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
		display: flex;
		justify-content: flex-end;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	::v-deep .el-scrollbar__thumb {
		display: none !important;
	}
</style>
<style>
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>